@import 'theme.scss';

.LocalNav__container {
  background: $color-white;

  .LocalNav__GlobalAlert {
    color: $color-black;
    margin: 0;
    padding: 0 1rem;
  }

  .LocalNav__ShareableBallotBanner {
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;

    .Notification__body .Button {
      margin-top: 0.5rem;
    }
  }
}

.LocalNav {
  align-items: center;
  background: $color-white;
  border-bottom: 1px solid #dedede;
  display: flex;
  height: $nav-height;
  justify-content: space-between;
}

.LocalNav__ViewBallotButton {
  margin-right: 1rem;
  padding: 0.5rem 1.5rem;

  & > * {
    color: white;
    text-decoration: none;
  }
}

.LocalNav__center {
  justify-content: center;
}

.LocalNav__sticky {
  position: sticky;
  top: 0;
}

.LocalNav__address {
  color: $color-gray-dark;
  margin-right: 0.5rem;
}

.LocalNav__BrandContainer {
  display: flex;
  height: 100%;
}

.LocalNav__Brand {
  display: flex;
  height: 100%;
  width: 10rem;

  @media (max-width: 376px) {
    max-width: 4rem;
    min-width: 4rem;
  }
}

.LocalNav__Brand img {
  margin-left: 0.3rem;
  margin-right: -4rem;
  max-width: 10rem;
  min-width: 10rem;
  object-fit: contain;
  object-position: 0 center;
  padding-right: 1rem;

  @media (max-width: 376px) {
    max-width: 4rem;
    min-width: 4rem;
  }
}

.LocalNav__Inner {
  align-items: center;
  display: flex;
  height: 100%;
  margin-right: 0.5rem;
}

.LocalNav .Button {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}

.LocalNav__FeatureButtons {
  align-items: center;
  border-right: 1px solid $color-gray-dark;
  display: none;
  /* Hide on smaller screens */
  height: 2.5rem;
  margin-right: 1.5rem;
  padding-right: 0.5rem;

  &:only-child {
    border-right: unset;
    margin-right: 0;
    .Button {
      margin-right: 0;
    }
  }
}

.LocalNav__FeatureButtons:empty {
  display: none;
}

.LocalNav__FeatureButtons .LocalNav__BtnAddress {
  align-content: center;
  border-radius: 50%;
  display: flex;
  font-size: 1.25rem;
  height: 2.25rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0;
  width: 2.25rem;
  align-items: center;
}

.LocalNav__Back {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  left: 0;
  margin-left: 1rem;
  padding: 0;
  position: absolute;
}

.LocalNav__BtnRegistration {
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;
}

/* Hide buttons on small screens */
.LocalNav__BtnMaptv {
  display: none;
}

.LocalNav .Button--with-icon svg {
  margin: 0;
  vertical-align: -0.2em;
}

.LocalNav__Left {
  display: flex;
  height: 100%;
}

@media (min-width: 768px) {
  .LocalNav__center .LocalNav__Inner {
    position: absolute;
    right: 0;
  }

  .LocalNav {
    height: $nav-height-lg;
  }

  .LocalNav__container {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
  }

  .LocalNav__container .Notification {
    height: $global-alert-height;
  }

  .LocalNav__container .Notification.LocalNav__ShareableBallotBanner {
    height: $share-banner-height;
  }

  .LocalNav__BtnMaptv {
    display: flex;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
  }

  .LocalNav__BtnRegistration {
    font-size: 0.875rem;
  }

  .LocalNav__FeatureButtons {
    display: flex;
  }

  .LocalNav .Button {
    margin-right: 1rem;
    padding: 0.5rem 1.5rem;
  }

  .LocalNav__FeatureButtons .LocalNav__BtnAddress {
    padding: 0;
  }

  .LocalNav__ShareableBallotBanner {
    font-size: 1rem;
  }

  .LocalNav__ShareableBallotBanner .Notification__body .Button {
    margin-left: 1rem;
    margin-top: 0;
  }

  .LocalNav__ShareableBallotBanner .Notification__body {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@media print {
  .LocalNav {
    display: none;
  }

  .LocalNav__FeatureButtons {
    display: none;
  }
}
